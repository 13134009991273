<template>
  <div>
    <v-app-bar app flat dark color="black" class="no-print">
      <v-spacer></v-spacer>
      <div class="text-h6 d-md-none">
        Cluster 11 - 2023 - U{{ ageGroup.substring(1) }}
      </div>
      <div class="text-h6 d-none d-md-block">
        Football West - Cluster 11 - 2023 - Under {{ ageGroup.substring(1) }}
      </div>
      <v-spacer></v-spacer>
      <AgeGroupSwitcher :ageGroup="ageGroup" />
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container fluid v-if="!loading">
        <router-view :key="`subroute-${$route.path}`" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import AgeGroupSwitcher from '@/components/admin/AgeGroupSwitcher'

export default {
  name: 'ClusterSection',
  components: {
    AgeGroupSwitcher,
  },
  props: {
    ageGroup: String,
  },
  data: () => ({
    loading: true,
  }),
  async created() {
    const fixtures = (
      await ApiService.all('fixtures', {
        age_group: this.ageGroup,
      })
    ).data
    await this.$store.dispatch('setFixtures', fixtures)
    this.loading = false
  },
}
</script>
