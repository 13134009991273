<template>
  <v-row>
    <Progress v-if="loading" />
    <template v-else>
      <v-col cols="12" sm="6">
        <v-card elevation="0">
          <v-card-text>
            <p class="text-h5">{{ team.name }}</p>
            <p>
              Game Leader: <b>{{ team.game_leader }}</b>
            </p>
            <p class="pb-2" v-if="team.helper">
              Helper: <b>{{ team.helper }}</b>
            </p>
            <div v-for="teamPlayer in sortedPlayers" :key="teamPlayer.id">
              <Player
                :player="teamPlayer"
                :color="player.color"
                class="mb-4 block"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" v-if="games.length">
        <v-card elevation="0">
          <v-card-text>
            <p class="text-h5">Games</p>
            <p>
              <GameInfo v-for="game in games" :key="game.id" :game="game" />
            </p>
            <center>
              <v-btn
                color="green"
                dark
                href="/images/nash-pitches-marked-updated.png"
                target="_blank"
                v-if="fixture.venue_id === 3"
                class="mt-4"
              >
                <v-icon left> mdi-soccer-field </v-icon>
                Pitch Layout
              </v-btn>
            </center>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-n8">
        <v-card elevation="0">
          <v-card-text>
            <p class="text-justify">
              <template v-if="fixture.venue_id === player.venue_id">
                If possible, please come down at least <b>30mins early</b> to
                help out if any extra jobs pop up at the venue.
              </template>
              Game Helpers should stay close to their subs and keep track of the
              time rotation for equal game play. If you have any last minute
              questions, please post in the WhatsApp group.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { format, parse } from 'date-fns'
import ApiService from '@/services/ApiService'
import GameInfo from '@/components/info/GameInfo'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'

export default {
  name: 'TeamInfo',
  components: {
    GameInfo,
    Player,
    Progress,
  },
  props: {
    team: Object,
    fixture: Object,
  },
  data: () => ({
    loading: true,
    games: [],
  }),
  async mounted() {
    this.loading = true
    try {
      this.games = (
        await ApiService.find('games', {
          team_id: this.team.club_team_id,
          fixture_id: this.fixture.id,
        })
      ).data
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState(['player']),
    sortedPlayers() {
      return this.team.players.slice().sort((player1, player2) => {
        const number1 = player1.id === this.player.id ? -1 : player1.number
        const number2 = player2.id === this.player.id ? -1 : player2.number
        return number1 - number2
      })
    },
  },
  methods: {
    textTime(isoTime) {
      return format(parse(isoTime, 'HH:mm:ss', new Date()), 'h:mmaaa')
    },
  },
}
</script>
