<template>
  <v-card flat class="teams px-4 pb-6">
    <Progress v-if="loading" />
    <v-card-title>
      <RoundNavigator title="Teams Allocation" />
    </v-card-title>
    <v-row>
      <v-col cols="2" class="no-print" v-if="availablePlayers.length">
        <draggable
          v-model="availablePlayers"
          group="players"
          class="not-assigned px-4"
        >
          <Player
            v-for="player in availablePlayers"
            :key="player.id"
            :player="player"
            :color="campaign.color"
            :withParent="player.parent_helper == 1"
            class="mb-4 my-2 player"
          />
        </draggable>
      </v-col>
      <v-col>
        <v-row>
          <v-col
            cols="6"
            lg="4"
            xl="2"
            v-for="team in teams"
            :key="team.key"
            class="team-card"
          >
            <v-card height="100%" :class="{ 'no-print': team.inactive }">
              <v-card-text class="team">
                <div
                  class="text-h5 team-name mb-2"
                  :class="{
                    inactive: team.inactive,
                    'text--primary': !team.inactive,
                    'text--lighten-3': team.inactive,
                  }"
                >
                  {{ team.name }}
                </div>
                <template v-if="!team.inactive">
                  <div class="leader mb-1">
                    <v-edit-dialog
                      @open="team.game_leader = leader(team, 0)"
                      @close="teams = [...teams]"
                      large
                    >
                      Game Leader:
                      <b class="leader-name">{{ leader(team, 0) }}</b>
                      <template v-slot:input>
                        <v-text-field
                          v-model="team.game_leader"
                          label="Game Leader"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                  <div class="leader mb-1">
                    <v-edit-dialog
                      @open="team.helper = leader(team, 1)"
                      @close="teams = [...teams]"
                      large
                    >
                      Helper: <b class="leader-name">{{ leader(team, 1) }}</b>
                      <template v-slot:input>
                        <v-text-field
                          v-model="team.helper"
                          label="Helper"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                  <draggable
                    v-model="team.players"
                    group="players"
                    class="team-players mt-2 pt-6"
                    @end="updateTeamsWeight()"
                  >
                    <Player
                      v-for="player in team.players"
                      :key="player.id"
                      :player="player"
                      :color="campaign.color"
                      class="mb-4 player"
                    />
                  </draggable>
                </template>
              </v-card-text>
              <div class="text-overline pitches pa-2 white">
                {{ team.pitches }}
              </div>
              <div class="toggle pa-2 white" v-if="!team.players.length">
                <v-icon
                  v-if="team.inactive"
                  color="green"
                  @click="toggleTeam(team)"
                >
                  mdi-delete-off
                </v-icon>
                <v-icon v-else color="red darken-4" @click="toggleTeam(team)">
                  mdi-delete
                </v-icon>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="availablePlayers.length === 0" class="no-print">
      <v-col>
        <draggable
          v-model="availablePlayers"
          group="players"
          class="not-assigned px-4"
        >
        </draggable>
      </v-col>
    </v-row>
    <v-row class="mt-4 no-print">
      <v-col cols="1" class="grey--text text--lighten-1 pt-8">
        {{ teamsWeight }}
      </v-col>
      <v-col cols="11" class="text-right">
        <v-btn
          :disabled="allocatedPlayersCount === 0"
          class="ma-2"
          @click="clear"
        >
          <v-icon left> mdi-eraser </v-icon>
          Clear
        </v-btn>
        <v-btn
          :disabled="allocatedPlayersCount === 0"
          class="ma-2"
          @click="print"
        >
          <v-icon left> mdi-printer </v-icon>
          Print
        </v-btn>
        <v-btn color="green" class="ma-2" dark @click="randomise">
          <v-icon left> mdi-sync </v-icon>
          Randomise
        </v-btn>
        <v-btn color="primary" class="ma-2" large @click="save">
          <v-icon left> mdi-content-save </v-icon>
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus'
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import draggable from 'vuedraggable'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import PrintableMixin from '@/mixins/PrintableMixin'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'
import RoundNavigator from '@/components/admin/RoundNavigator'

export default {
  name: 'TeamsAllocation',

  components: {
    draggable,
    Player,
    Progress,
    RoundNavigator,
  },

  mixins: [FixtureBasedMixin, PrintableMixin],

  props: {
    campaign: Object,
  },

  data: () => ({
    loading: true,
    allPlayers: [],
    availablePlayers: [],
    clubTeams: [],
    teams: [],
    teammates: {},
    teamsWeight: 0,
  }),

  computed: {
    ...mapState(['user']),
    allocatedPlayersCount() {
      let count = 0
      this.teams.forEach((team) => (count += team.players.length))
      return count
    },
  },

  async mounted() {
    this.loading = true
    try {
      const fixtureParam = {
        fixture_id: this.fixture.id,
        campaign_id: this.campaign.id,
      }
      this.allPlayers = (
        await ApiService.get('fixtureTeams', 'availablePlayers', fixtureParam)
      ).data
      this.teams = (await ApiService.list('fixtureTeams', fixtureParam)).data
      this.clubTeams = (
        await ApiService.list('clubTeams', {
          campaign_id: this.fixture.campaign_id,
        })
      ).data.filter((clubTeam) => clubTeam.club_id === this.campaign.club_id)
      this.availablePlayers = this.allPlayers.filter(
        (player) =>
          !this.teams.some((team) =>
            team.players.some((teamPlayer) => teamPlayer.id === player.id),
          ),
      )
      await this.buildTeamsArray(fixtureParam)
      const allTeams = (
        await ApiService.all('fixtureTeams', {
          campaign_id: this.campaign.id,
        })
      ).data
      this.buildTeammatesArray(this.teammates, allTeams)
      this.updateTeamsWeight()
    } finally {
      this.loading = false
    }
  },

  methods: {
    async buildTeamsArray(fixtureParam) {
      var games = (await ApiService.list('games', fixtureParam)).data
      if (this.teams.length) {
        this.teams.forEach((team) => {
          if (team.players.length) {
            team.players = team.players
              .map((player) => this.fullPlayerData(player))
              .filter((player) => !!player)
          } else {
            team.inactive = true
          }
          team.pitches = [
            ...new Set(
              games
                .filter(
                  (game) =>
                    game.home_team_id === team.club_team_id ||
                    game.away_team_id === team.club_team_id,
                )
                .map((game) => game.pitch),
            ),
          ].join(', ')
        })
      } else {
        this.clubTeams.forEach((clubTeam) => {
          this.teams.push({
            club_team_id: clubTeam.id,
            name: clubTeam.name,
            players: [],
          })
        })
      }
    },

    buildTeammatesArray(teammates, allTeams) {
      allTeams
        .map((team) => team.players.map((player) => player.id))
        .forEach((team) => {
          team.forEach((playerId) => {
            if (!teammates[playerId]) {
              teammates[playerId] = {}
            }
            team.forEach((teammateId) => {
              if (playerId != teammateId) {
                if (!teammates[playerId][teammateId]) {
                  teammates[playerId][teammateId] = 0
                }
                teammates[playerId][teammateId]++
              }
            })
          })
        })
    },

    fullPlayerData(player) {
      return (
        this.allPlayers.find((fullPlayer) => fullPlayer.id === player.id) ||
        player
      )
    },

    playersWithHelpers(players, available) {
      return players.filter(
        (player) => player.parent_helper === (available ? 1 : 0),
      )
    },

    leader(team, index) {
      const existing = index ? team.helper : team.game_leader
      if (existing) {
        return existing
      }
      const player = this.playersWithHelpers(team.players, true)[index]
      return player
        ? `${player.parent_first_name} (${player.first_name} ${player.last_name_initial})`
        : ''
    },

    clear() {
      this.teams.forEach((team) => {
        this.availablePlayers.push(...team.players)
        team.players = []
        delete team.game_leader
        delete team.helper
      })
      this.updateTeamsWeight()
    },

    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return [...array]
    },

    shuffleAndAllocate(teams, players, currentTeamIndex) {
      if (teams.length && players.length) {
        this.shuffle(players).forEach((player) => {
          teams[currentTeamIndex++ % teams.length].players.push(player)
        })
      }
      return currentTeamIndex
    },

    calculateTeamsWeight(teammates) {
      return Object.values(teammates)
        .map((playerMates) =>
          // "punish" frequent teammates
          Object.values(playerMates)
            .map((count) => count * count)
            .reduce((sum, value) => (sum += value), 0),
        )
        .reduce((sum, value) => (sum += value), 0)
    },

    updateTeamsWeight() {
      const newTeammates = JSON.parse(JSON.stringify(this.teammates))
      this.buildTeammatesArray(newTeammates, this.teams)
      this.teamsWeight = this.calculateTeamsWeight(newTeammates)
    },

    randomise() {
      this.clear()
      const withHelpers = this.playersWithHelpers(this.availablePlayers, true)
      const withSubHelpers = withHelpers.filter((player) =>
        player.comment?.toLowerCase().includes('ubs'),
      )
      const withLeaders = withHelpers.filter(
        (player) => !withSubHelpers.includes(player),
      )
      const withoutHelpers = this.playersWithHelpers(
        this.availablePlayers,
        false,
      )
      const maxTeams = Math.min(
        Math.floor(this.allPlayers.length / 4),
        this.teams.length,
      )
      let tries = 1000
      const bestAttempt = {
        weight: Number.MAX_VALUE,
        teams: this.teams,
      }
      while (tries-- > 0) {
        const teamsToAllocate = JSON.parse(JSON.stringify(this.teams))
          .filter((team) => !team.inactive)
          .slice(0, maxTeams || 1)
        const newTeammates = JSON.parse(JSON.stringify(this.teammates))
        let teamIndex = this.shuffleAndAllocate(teamsToAllocate, withLeaders, 0)
        teamIndex = this.shuffleAndAllocate(
          teamsToAllocate,
          withSubHelpers,
          teamIndex,
        )
        this.shuffleAndAllocate(teamsToAllocate, withoutHelpers, teamIndex)
        this.buildTeammatesArray(newTeammates, teamsToAllocate)
        const newWeight = this.calculateTeamsWeight(newTeammates)
        if (newWeight < bestAttempt.weight) {
          bestAttempt.weight = newWeight
          bestAttempt.teams = teamsToAllocate
        }
      }
      this.teams.forEach((team) => {
        if (
          !bestAttempt.teams.find(
            (newTeam) => newTeam.club_team_id === team.club_team_id,
          )
        ) {
          team.inactive = true
          bestAttempt.teams.push(team)
        }
      })
      this.teams = bestAttempt.teams
      this.availablePlayers = []
      this.updateTeamsWeight()
    },

    toggleTeam(team) {
      team.inactive = !team.inactive
      this.teams = [...this.teams]
    },

    async save() {
      this.loading = true
      let error = false
      let message = 'Teams have been successfully saved'
      try {
        const payload = this.teams.map((team) => ({
          fixture_id: this.fixture.id,
          club_team_id: team.club_team_id,
          game_leader: this.leader(team, 0),
          helper: this.leader(team, 1),
          players: team.players.map((player) => player.id),
        }))
        await ApiService.create('fixtureTeams', payload)
      } catch (exc) {
        error = true
        message = 'Could not save teams'
        console.error(exc)
      }
      EventBus.$emit('feedback', { message, error })
      this.loading = false
    },
  },
}
</script>

<style>
.team-players .player,
.not-assigned .player {
  display: block;
}
.team-players .player .v-chip:has(.number) {
  min-width: 4.5rem;
}
</style>

<style scoped>
.team {
  position: relative;
}
.inactive {
  text-decoration: line-through;
}
.player {
  cursor: move;
}
.leader-name {
  white-space: nowrap;
}
.not-assigned,
.team-players {
  min-height: 4rem;
  padding: 8px;
  border: 1px dashed lightgray;
  height: 100%;
}
.toggle,
.pitches {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.toggle {
  cursor: pointer;
}
.pitches {
  line-height: 1rem;
}

@media print {
  .row > div {
    padding: 5px;
  }
  .team {
    padding: 10px;
    line-height: 1rem;
  }
  .team-players {
    border: 0;
    margin-top: -8px !important;
  }
  .team-players div:last-child {
    margin-bottom: 20px !important;
  }
  .player {
    margin-bottom: 10px !important;
  }
  .team-card {
    break-inside: avoid;
  }
  .pitches {
    font-size: 1rem !important;
  }
}
</style>
