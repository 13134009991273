<template>
  <v-form v-model="valid">
    <v-container class="full-screen-container">
      <Progress v-if="loading" />
      <v-row>
        <v-col class="text-h6"> <span v-if="!id">New </span>Player </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            filled
            v-model="record.first_name"
            label="First Name *"
            counter
            maxlength="255"
            :rules="[(v) => !!v || 'First Name is required']"
            ref="firstName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            filled
            v-model="record.last_name_initial"
            label="Last Name Initials *"
            counter
            maxlength="3"
            :rules="[
              (v) => !!v || 'Last Name Initials are required',
              (v) =>
                !v ||
                v.length <= 3 ||
                'Initials cannot have more than 3 characters',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            filled
            v-model="record.pin"
            label="Pin *"
            counter
            maxlength="4"
            :rules="[
              (v) => !!v || 'Pin is required',
              (v) => !v || v.length == 4 || 'Pin must be 4 characters long',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            filled
            v-model.number="record.number"
            label="Number"
            maxlength="2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            filled
            :items="['7/8', '9/10', '11/12', '13/14']"
            label="Shirt size"
            v-model="record.shirt_size"
          ></v-select>
        </v-col>
      </v-row>
      <FormButtons
        :valid="valid"
        recordType="Player"
        @save="save"
        @cancel="back"
        @delete="remove"
      />
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'PlayerForm',
  props: {
    campaign: Object,
  },
  mixins: [FormMixin],
  data: () => ({
    type: 'Player',
    module: 'players',
  }),
  mounted() {
    setTimeout(() => {
      this.$refs.firstName.focus()
    }, 300)
  },
  methods: {
    recordToSave() {
      return {
        ...this.record,
        campaign_id: this.campaign.id,
        number: Number.isInteger(this.record.number)
          ? this.record.number
          : null,
      }
    },
    listRouteParams() {
      return {
        season: this.campaign.season,
        club: this.campaign.club,
        ageGroup: this.campaign.age_group,
      }
    },
  },
}
</script>
