<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ header }}</span>
    </v-card-title>
    <v-card-text class="pt-4">
      {{ text }}
    </v-card-text>
    <v-card-actions class="mr-2 pb-4">
      <v-spacer></v-spacer>
      <v-btn @click="$emit('cancelled')" class="mr-5"> Cancel </v-btn>
      <v-btn color="primary" @click="$emit('confirmed')">
        {{ confirmButton }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    header: String,
    text: String,
    confirmButton: String,
  },
}
</script>
