<template>
  <div>
    <v-app-bar app flat dark :color="playerSet ? player.color : 'primary'">
      <v-spacer></v-spacer>
      <div class="text-h6" v-if="playerSet">
        {{ player.age_group_name }} -
        {{ player.club_name }}
      </div>
      <div class="text-h6" v-else>MiniRoos</div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container fluid>
        <PlayerSelector v-if="!playerSet" />
        <FixtureInfo v-else-if="fixtures.length" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import FixtureInfo from '@/components/info/FixtureInfo'
import PlayerSelector from '@/components/info/PlayerSelector'

export default {
  name: 'InfoSection',
  components: {
    FixtureInfo,
    PlayerSelector,
  },
  computed: {
    ...mapState(['player', 'fixtures']),
    playerSet() {
      return this.player && this.player.id
    },
  },
  async beforeCreate() {
    const playerData = localStorage.getItem('player')
    if (playerData) {
      let currentData = JSON.parse(playerData)
      if (currentData && currentData.pin) {
        // get up to date player data
        const player = (
          await ApiService.find('players', {
            pin: currentData.pin,
            first_name: currentData.first_name,
          })
        ).data
        await this.$store.dispatch('setPlayer', player)
        if (this.fixtures.length < 1) {
          const fixtures = (
            await ApiService.all('fixtures', {
              campaign_id: player.campaign_id,
            })
          ).data
          await this.$store.dispatch('setFixtures', fixtures)
        }
      }
    }
  },
}
</script>
