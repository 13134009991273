<template>
  <v-form v-model="valid">
    <v-container>
      <Progress v-if="loading" />
      <v-row>
        <v-col class="text-h6"> <span v-if="!id">New </span>User </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            v-model="record.name"
            label="Name *"
            counter
            maxlength="255"
            :rules="[(v) => !!v || 'Name is required']"
            ref="name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            v-model="record.email"
            label="E-mail"
            counter
            maxlength="255"
            :rules="[(v) => !v || /.+@.+/.test(v) || 'E-mail must be valid']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            type="password"
            v-model="record.currentPassword"
            label="Current password"
            counter
            maxlength="255"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-h6"> Update password </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            type="password"
            v-model="record.newPassword"
            label="New password"
            counter
            maxlength="255"
            :rules="[
              (v) =>
                !v ||
                v.length >= 8 ||
                'Password must be at least 8 characters long',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            type="password"
            v-model="record.repeatPassword"
            label="Repeat password"
            counter
            maxlength="255"
            :rules="[
              (v) => v === record.newPassword || 'New password doesn\'t match',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-alert text type="error" v-if="error" class="mt-4 mb-n2">
        {{ error }}
      </v-alert>
      <FormButtons
        :valid="valid"
        :recordType="type"
        @save="save"
        @cancel="back"
      />
    </v-container>
  </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'UserForm',
  mixins: [FormMixin],
  props: {
    popup: Boolean,
  },
  data: () => ({
    type: 'User',
    module: 'profile',
    error: null,
  }),
  mounted() {
    setTimeout(() => {
      this.$refs.name.focus()
    }, 300)
    this.record.currentPassword = ''
    this.record.newPassword = ''
    this.record.repeatPassword = ''
  },
  methods: {
    async save() {
      this.error = null
      this.loading = true
      try {
        const response = (
          await ApiService.update(this.module, this.id, this.record)
        ).data
        if (response.error) {
          this.error = response.error
        } else {
          this.done(`${this.type} saved successfully`)
        }
      } catch (error) {
        this.done(`Could not save ${this.type}`, true)
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
