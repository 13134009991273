<template>
  <v-dialog v-model="dialogVisible" scrollable persistent max-width="850px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        color="green"
        dark
        v-bind="attrs"
        v-on="on"
        class="mr-4"
      >
        <v-icon left> mdi-pencil </v-icon>
        Edit Team Names
      </v-btn>
    </template>
    <v-card>
      <Progress v-if="loading" />
      <v-card-title class="grey lighten-2">Edit Team Names</v-card-title>

      <v-card-text class="dialog-contents pt-4">
        <v-form v-model="valid">
          <v-row class="px-2" v-for="club in clubs" :key="club.id">
            <v-col cols="12">
              <Team :name="club.name" :color="club.color" />
            </v-col>
            <v-col
              cols="3"
              class="p-2"
              v-for="team in clubTeams(club)"
              :key="team.id"
            >
              <v-text-field
                filled
                hide-details="auto"
                v-model="team.name"
                maxlength="255"
                :rules="[(v) => !!v || 'Name is required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <FormButtons
          :valid="valid"
          @save="save"
          @cancel="cancel"
          class="mt-n10 mb-1 mr-2"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import ApiService from '@/services/ApiService'
import FormButtons from '@/components/admin/FormButtons'
import Team from '@/components/chips/Team'

export default {
  name: 'FormInDialog',

  components: { FormButtons, Team },

  props: {
    teams: Array,
    disabled: Boolean,
  },

  data() {
    return {
      loading: false,
      valid: false,
      dialogVisible: false,
    }
  },

  computed: {
    clubs() {
      return this.teams
        .map((team) => {
          return {
            id: team.club_id,
            name: team.club,
            color: team.color,
          }
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id),
        )
    },
  },

  methods: {
    clubTeams(club) {
      return this.teams.filter((team) => team.club_id === club.id)
    },

    async save() {
      this.loading = true
      try {
        let error = false
        let message = 'Team names have been successfully updated'
        try {
          const payload = this.teams.map((team) => {
            return {
              id: team.id,
              name: team.name,
              club_id: team.club_id,
              campaign_id: team.campaign_id,
            }
          })
          await ApiService._internalPost('clubTeams', payload)
          this.dialogVisible = false
        } catch (exc) {
          error = true
          message = 'Could not update team names'
          console.error(exc)
        }
        EventBus.$emit('feedback', { message, error })
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.dialogVisible = false
    },
  },
}
</script>
