<template>
  <v-snackbar app top v-model="show" :color="error ? 'error' : 'success'">
    <v-row align="center">
      <v-col cols="1" class="pr-8">
        <v-icon left v-if="error">mdi-alert</v-icon>
        <v-icon left v-else>mdi-check</v-icon>
      </v-col>
      <v-col>
        {{ message }}
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-btn icon color="white" v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'Feedback',
  data: () => ({
    show: false,
    message: null,
    error: false,
  }),
  mounted() {
    EventBus.$on('feedback', (payload) => {
      this.message = payload.message
      this.error = payload.error
      this.show = true
    })
    EventBus.$on('feedback:clear', () => {
      this.show = false
    })
  },
  destroyed() {
    EventBus.$off('feedback')
    EventBus.$off('feedback:clear')
  },
}
</script>

<style scoped>
.menu-item {
  text-decoration: none;
}
</style>
