import { render, staticRenderFns } from "./AdminSection.vue?vue&type=template&id=6a12c298&scoped=true&"
import script from "./AdminSection.vue?vue&type=script&lang=js&"
export * from "./AdminSection.vue?vue&type=script&lang=js&"
import style0 from "./AdminSection.vue?vue&type=style&index=0&id=6a12c298&scoped=true&lang=css&"
import style1 from "./AdminSection.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a12c298",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VContainer,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMain,VMenu,VSpacer})
