import ApiService from '@/services/ApiService'
import Progress from '@/components/utils/Progress'

export default {
  components: {
    Progress,
  },
  data: () => ({
    loading: true,
    roles: [],
    helpers: [],
  }),
  computed: {
    confirmedCount() {
      return this.helpers.filter((helper) => helper.confirmed).length
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.roles = (
        await ApiService.list('helperRoles', {
          campaign_id: this.fixture.campaign_id,
        })
      ).data
      this.helpers = []
      const availabilities = (
        await ApiService.list('availabilities/withRoles', {
          fixture_id: this.fixture.id,
        })
      ).data
      availabilities.forEach((availability) =>
        availability.helper_roles.forEach((helperRole) => {
          this.helpers.push({
            id: availability.id,
            name: availability.helper_first_name,
            player: `${availability.first_name} ${availability.last_name_initial}`,
            roleId: helperRole.id,
            confirmed: helperRole.pivot.confirmed,
          })
        }),
      )
      this.sortHelpers()
    } finally {
      this.loading = false
    }
  },
  methods: {
    sortHelpers() {
      this.helpers.sort((a, b) => {
        if (a.confirmed == b.confirmed) {
          return a.name.localeCompare(b.name)
        }
        return b.confirmed - a.confirmed
      })
    },
    helpersForRole(roleId) {
      return this.helpers.filter((helper) => helper.roleId === roleId)
    },
  },
}
