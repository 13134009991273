<template>
  <v-card flat class="px-4 pb-6 fixtures">
    <Progress v-if="loading" />
    <v-card-title>
      <RoundNavigator :title="`Fixtures Builder`">
        <ClubTeamsDialog :teams="teams" />
      </RoundNavigator>
    </v-card-title>
    <v-row class="no-print">
      <v-col cols="12" class="text-center">
        <Team
          v-for="(team, index) in teams"
          :name="team.name"
          :key="index"
          :color="!team.unavailable ? team.color : null"
          :close="!team.unavailable"
          @click:name="enableTeam(team)"
          @click:close="disableTeam(team)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        xl="4"
        offset-xl="1"
        v-for="(round, roundIndex) in order"
        :key="roundIndex"
      >
        <v-card height="100%">
          <v-card-text>
            <p class="text-h5 text--primary">Game {{ roundIndex + 1 }}</p>
            <v-row>
              <v-col cols="4">
                <v-row dense align="center">
                  <v-col
                    cols="12"
                    align="center"
                    v-for="(pitch, index) in pitches"
                    :key="index"
                    class="ml-4"
                    :class="{ 'no-print': noGame(roundIndex, index) }"
                    ><div class="pitch">
                      {{ adjustedPitchName(pitch.name, roundIndex) }}
                    </div></v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="8">
                <draggable
                  :list="round"
                  class="row row--dense"
                  :move="handleMove"
                  @end="handleDragEnd(roundIndex)"
                  ref="draggableList"
                >
                  <v-col
                    cols="6"
                    align="center"
                    v-for="(team, index) in round"
                    :key="index"
                    :class="{
                      'ml-n4': index % 2 === 0,
                    }"
                    class="pa-1"
                  >
                    <Team
                      :name="team.name"
                      :color="team.color"
                      :class="{ 'no-print': team.bye }"
                      :close="!team.bye"
                      @click:close="switchToBye(roundIndex, index)"
                    />
                  </v-col>
                </draggable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-4 no-print">
      <v-col cols="12" class="text-right">
        <v-snackbar :timeout="-1" :value="warnings.length" left color="error">
          <v-row align="center">
            <v-col cols="1" class="pr-8">
              <v-icon left>mdi-alert</v-icon>
            </v-col>
            <v-col v-if="showWarnings">
              <ul>
                <li v-for="(warning, index) in warnings" :key="index">
                  {{ warning }}
                </li>
              </ul>
            </v-col>
            <v-col v-else>
              <b>{{ warnings.length }}</b> warnings
            </v-col>
          </v-row>
          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              @click="showWarnings = false"
              v-if="showWarnings"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn v-else text @click="showWarnings = true"> Show </v-btn>
          </template>
        </v-snackbar>
        <v-btn :disabled="order[0].length === 0" class="ma-2" @click="clear">
          <v-icon left> mdi-eraser </v-icon>
          Clear
        </v-btn>
        <v-btn :disabled="order[0].length === 0" class="ma-2" @click="print">
          <v-icon left> mdi-printer </v-icon>
          Print
        </v-btn>
        <v-btn class="ma-2" @click="simple">
          <v-icon left> mdi-sort </v-icon>
          Simple
        </v-btn>
        <v-btn
          color="green"
          dark
          class="ma-2"
          @click="randomise"
          :loading="generating"
        >
          <v-icon left> mdi-sync </v-icon>
          Randomise
        </v-btn>
        <v-btn color="primary" class="ma-2" large @click="save">
          <v-icon left> mdi-content-save </v-icon>
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus'
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import draggable from 'vuedraggable'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import PrintableMixin from '@/mixins/PrintableMixin'
import Progress from '@/components/utils/Progress'
import ClubTeamsDialog from '@/components/admin/ClubTeamsDialog'
import RoundNavigator from '@/components/admin/RoundNavigator'
import Team from '@/components/chips/Team'

export default {
  name: 'GamesBuilder',

  components: {
    draggable,
    ClubTeamsDialog,
    Progress,
    RoundNavigator,
    Team,
  },

  mixins: [FixtureBasedMixin, PrintableMixin],

  data: () => ({
    loading: true,
    generating: false,
    dragMovingIndex: null,
    dragFutureIndex: null,
    warnings: [],
    showWarnings: true,
    bye: {
      color: '',
      name: '-',
      bye: 1,
    },
    teams: [],
    pitches: [],
    order: [[], []],
  }),

  async mounted() {
    this.loading = true
    try {
      this.teams = (
        await ApiService.list('clubTeams', {
          campaign_id: this.fixture.campaign_id,
        })
      ).data
      this.pitches = (
        await ApiService.list('pitches', {
          venue_id: this.fixture.venue_id,
        })
      ).data
      if (this.pitches.length === 0) {
        EventBus.$emit('feedback', {
          message: `There are no pitches defined for this venue. Fixtures cannot be generated.`,
          error: true,
        })
      }
      const games = (
        await ApiService.list('games', {
          fixture_id: this.fixture.id,
        })
      ).data

      if (games.length > 0) {
        const gamesOrder = [[], []]
        let allByes = true
        games.forEach((game) => {
          const homeTeamIndex =
            this.pitches.findIndex((pitch) => pitch.id === game.pitch_id) * 2
          const homeTeam = game.home_team_id
            ? this.teams.find((team) => team.id === game.home_team_id)
            : this.bye
          const awayTeam = game.away_team_id
            ? this.teams.find((team) => team.id === game.away_team_id)
            : this.bye
          gamesOrder[game.order - 1][homeTeamIndex] = homeTeam
          gamesOrder[game.order - 1][homeTeamIndex + 1] = awayTeam
          if (game.home_team_id || game.away_team_id) {
            allByes = false
          }
        })
        if (!allByes) {
          this.teams.forEach((team) => {
            const round1Index = gamesOrder[0].indexOf(team)
            const round2Index = gamesOrder[1].indexOf(team)
            if (round1Index < 0 && round2Index < 0) {
              team.unavailable = true
            }
          })
          this.order = gamesOrder
          // fix draggable issue: https://github.com/SortableJS/Vue.Draggable/issues/603
          this.$refs.draggableList[0].updatePosition(0, 1)
          this.$refs.draggableList[0].updatePosition(1, 0)
          this.$refs.draggableList[1].updatePosition(0, 1)
          this.$refs.draggableList[1].updatePosition(1, 0)
          this.validate(true)
        }
      }
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapState(['user']),
    gamesCount() {
      return this.order[0].length + this.order[1].length
    },
    availableTeams() {
      return this.teams.filter((team) => !team.unavailable)
    },
  },

  methods: {
    adjustedPitchName(pitchName, roundIndex) {
      if (pitchName.indexOf('am') && roundIndex > 0) {
        return pitchName.replace('am', ':30')
      }
      return pitchName
    },
    disableTeam(team) {
      if (team.unavailable) {
        return
      }
      team.unavailable = true
      this.teams = [...this.teams]
      if (this.gamesCount) {
        this.order.forEach((round) => {
          const index = round.indexOf(team)
          if (index > -1) {
            round[index] = this.bye
          }
        })
        this.order = [...this.order]
        this.validate(true)
      }
    },

    enableTeam(team) {
      if (!team.unavailable) {
        return
      }
      team.unavailable = false
      this.teams = [...this.teams]
      if (this.gamesCount) {
        this.order.forEach((round) => {
          const index = round.indexOf(this.bye)
          if (index > -1) {
            round[index] = team
          }
        })
        this.order = [...this.order]
        this.validate(true)
      }
    },

    fitOrderIntoPitches(round) {
      while (round.length < this.pitches.length * 2) {
        round.push(this.bye)
      }
      while (round.length > this.pitches.length * 2) {
        round.pop()
      }
    },

    randomisedTeams() {
      const randomOrder = [...this.availableTeams]
      for (let i = randomOrder.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[randomOrder[i], randomOrder[j]] = [randomOrder[j], randomOrder[i]]
      }
      this.fitOrderIntoPitches(randomOrder)
      return randomOrder
    },

    clear() {
      this.order = [[], []]
    },

    simple() {
      this.clear()
      const half = Math.ceil(this.availableTeams.length / 2)
      const leftSide = this.availableTeams.slice(0, half)
      const rightSide = this.availableTeams.slice(half)
      const roundOrder = (left, right) => {
        const order = left.reduce(
          (arr, team, index) =>
            right[index]
              ? arr.concat([team, right[index]])
              : arr.concat([team]),
          [],
        )
        this.fitOrderIntoPitches(order)
        return order
      }
      this.order = [
        roundOrder(leftSide, rightSide),
        roundOrder(leftSide, rightSide.reverse()),
      ]
      this.validate(true)
      this.showWarnings = false
    },

    randomise() {
      this.generating = true
      this.clear()
      let tries = 100000
      const bestAttempt = {
        warnings: Number.MAX_VALUE,
        order: [],
      }
      do {
        this.order = [this.randomisedTeams(), this.randomisedTeams()]
        this.validate(false)
        if (this.warnings.length < bestAttempt.warnings) {
          bestAttempt.warnings = this.warnings.length
          bestAttempt.order = this.order
        }
      } while (this.warnings.length > 0 && --tries > 0)
      if (tries === 0) {
        this.order = bestAttempt.order
        EventBus.$emit('feedback', {
          message:
            'Could not generate valid fixtures. Please double check available teams.',
          error: true,
        })
      }
      this.validate(true)
      this.generating = false
    },

    opposition(round, team) {
      const index = round.indexOf(team)
      return index % 2 === 0 ? round[index + 1] : round[index - 1]
    },

    validate(checkTeamsPlay2Games) {
      this.warnings = []
      if (this.order.length === 0) {
        return true
      }

      this.order.forEach((round) => {
        for (let i = 0; i < round.length - 1; i += 2) {
          if (round[i].club === round[i + 1].club && !round[i].bye) {
            this.warnings.push(
              `${round[i].name} is scheduled to play ${
                round[i + 1].name
              } and both teams are from the same club`,
            )
          }
        }
      })

      this.order[0].forEach((team) => {
        const round1Opposition = this.opposition(this.order[0], team)
        const round2Opposition = this.opposition(this.order[1], team)
        if (
          round1Opposition === round2Opposition &&
          !team.bye &&
          !round1Opposition.bye &&
          !round2Opposition.bye
        ) {
          this.warnings.push(
            `${team.name} is scheduled to play ${round1Opposition.name} twice`,
          )
        }
      })

      if (checkTeamsPlay2Games) {
        this.availableTeams.forEach((team) => {
          const round1Index = this.order[0].indexOf(team)
          const round2Index = this.order[1].indexOf(team)
          if (round1Index < 0 && round2Index < 0) {
            this.warnings.push(
              `${team.name} is available but not scheduled to play any games`,
            )
          } else if (round1Index < 0 || round2Index < 0) {
            this.warnings.push(`${team.name} is only scheduled to play 1 game`)
          } else {
            const round1Opposition = this.opposition(this.order[0], team)
            const round2Opposition = this.opposition(this.order[1], team)
            if (round1Opposition.bye || round2Opposition.bye) {
              this.warnings.push(`${team.name} doesn't have an opponent`)
            }
          }
        })
      }
    },

    noGame(roundIndex, gameIndex) {
      const homeTeam = this.order[roundIndex][gameIndex * 2]
      const awayTeam = this.order[roundIndex][gameIndex * 2 + 1]
      return (!homeTeam || homeTeam.bye) && (!awayTeam || awayTeam.bye)
    },

    switchToBye(roundIndex, gameIndex) {
      this.order[roundIndex][gameIndex] = this.bye
      this.order = [...this.order]
      this.validate(true)
    },

    handleMove(event) {
      const { index, futureIndex } = event.draggedContext
      this.dragMovingIndex = index
      this.dragFutureIndex = futureIndex
      return false // disable sort
    },

    handleDragEnd(roundIndex) {
      const round = this.order[roundIndex]
      const futureItem = round[this.dragFutureIndex]
      const movingItem = round[this.dragMovingIndex]
      const reordered = [...round]
      reordered[this.dragFutureIndex] = movingItem
      reordered[this.dragMovingIndex] = futureItem
      this.order =
        roundIndex === 0
          ? [reordered, [...this.order[1]]]
          : [[...this.order[0]], reordered]
      this.validate(true)
    },

    gamePayload(pitch, round, homeTeamIndex) {
      const home = this.order[round][homeTeamIndex]
      const away = this.order[round][homeTeamIndex + 1]
      return {
        age_group_id: this.teams[0].age_group_id,
        fixture_id: this.fixture.id,
        pitch_id: pitch.id,
        order: round + 1,
        home_team_id: !home || home.bye ? null : home.id,
        away_team_id: !away || away.bye ? null : away.id,
      }
    },

    async save() {
      this.loading = true
      try {
        let error = false
        let message = 'Fixtures have been successfully saved'
        try {
          const payload = []
          let teamIndex = 0
          this.pitches.forEach((pitch) => {
            payload.push(this.gamePayload(pitch, 0, teamIndex))
            payload.push(this.gamePayload(pitch, 1, teamIndex))
            teamIndex += 2
          })
          await ApiService._internalPost('games', payload)
        } catch (exc) {
          error = true
          message = 'Could not save fixtures'
          console.error(exc)
        }
        EventBus.$emit('feedback', { message, error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.pitch {
  padding: 15px 0 11px;
}
</style>
