<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="mr-4">Players</span>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn color="primary" dark class="mb-2" @click="add">
            <v-icon left> mdi-plus </v-icon> Add Player
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.number`]="{ item }">
        <Player :player="item" :color="campaign.color" :hideName="true" />
      </template>
      <template v-slot:[`item.verified`]="{ item }">
        <YesNo :value="item.verified" />
      </template>
    </v-data-table>
    <ListFooter
      :module="module"
      @updated="loadData"
      :itemsCount="listData.total"
    ></ListFooter>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ListMixin from '@/mixins/ListMixin'
import YesNo from '@/components/chips/YesNo'
import Player from '@/components/chips/Player'

export default {
  name: 'PlayersList',
  components: {
    YesNo,
    Player,
  },
  props: {
    campaign: Object,
  },
  mixins: [ListMixin],
  data() {
    return {
      module: 'players',
      formRoute: 'player',
      headers: [
        { text: 'Number', value: 'number' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name Initial', value: 'last_name_initial' },
        { text: 'Pin', value: 'pin' },
        { text: 'Verified', value: 'verified' },
        { text: 'Shirt Size', value: 'shirt_size' },
      ],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    loadDataParams() {
      return { campaign_id: this.campaign.id }
    },
    formRouteParams() {
      return {
        season: this.campaign.season,
        club: this.campaign.club,
        ageGroup: this.campaign.age_group,
      }
    },
  },
}
</script>
