export default {
  methods: {
    adjustedHomeVenue(ageGroup, clubName, fixtureNumber) {
      if (ageGroup === 'u8') {
        if (clubName === 'Athena Athletic') {
          return [5, 6, 7, 8, 13, 14, 15, 16].includes(fixtureNumber)
        } else if (clubName === 'Athena Fire') {
          return [1, 2, 3, 4, 9, 10, 11, 12, 17, 18].includes(fixtureNumber)
        } else if (clubName === 'Athena Strikers') {
          return [1, 2, 5, 6, 9, 10, 13, 14, 17, 18].includes(fixtureNumber)
        } else if (clubName === 'Athena United') {
          return [3, 4, 7, 8, 11, 12, 15, 16].includes(fixtureNumber)
        }
      }
      return false
    },
  },
}
