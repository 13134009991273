<template>
  <v-app>
    <Feedback />
    <router-view :key="$route.path" class="print-content pb-4" />
    <Footer :version="versionNo" class="no-print" />
  </v-app>
</template>

<script>
import Feedback from '@/components/utils/Feedback'
import Footer from '@/components/utils/Footer'

export default {
  name: 'App',
  components: {
    Feedback,
    Footer,
  },
  data: () => ({
    versionNo: process.env.VUE_APP_VERSION,
  }),
}
</script>

<style scoped>
.theme--light.v-application {
  background-color: #eeeeee;
}
</style>

<style>
.print-only {
  display: none;
}
@media print {
  .print-content {
    margin-top: -5rem;
    margin-left: -5rem;
  }
  .v-application .text-h5 {
    font-size: 1.3rem !important;
  }
  .v-application--wrap,
  .v-application .v-main.grey {
    background-color: #fff !important;
  }
  .print-only {
    display: unset;
  }
  .no-print,
  .no-print *,
  .v-chip__close {
    display: none !important;
  }
}
</style>
