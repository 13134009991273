<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        :class="buttonClass"
      >
        <v-icon left> mdi-delete </v-icon>
        Delete {{ recordType }}
      </v-btn>
    </template>
    <ConfirmDialog
      :header="`Delete ${recordType}`"
      :text="`Are you sure you want to delete this ${recordType}?`"
      confirmButton="Delete"
      @confirmed="
        dialog = false
        $emit('confirmed')
      "
      @cancelled="dialog = false"
    />
  </v-dialog>
</template>

<script>
import ConfirmDialog from '@/components/admin/ConfirmDialog'

export default {
  name: 'ConfirmDeleteDialog',
  components: {
    ConfirmDialog,
  },
  props: {
    recordType: String,
    buttonClass: String,
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
