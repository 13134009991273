import { EventBus } from '@/event-bus'
import ApiService from '@/services/ApiService'
import FormButtons from '@/components/admin/FormButtons'
import Progress from '@/components/utils/Progress'

export default {
  components: {
    FormButtons,
    Progress,
  },
  props: {
    id: [Number, String],
  },
  data: () => ({
    record: {},
    valid: false,
    loading: true,
    createdId: null,
    modified: false,
  }),
  watch: {
    record: {
      deep: true,
      handler() {
        this.modified = true
      },
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  async mounted() {
    this.loading = true
    await this.loadData()
    this.loading = false
    this.$nextTick(() => {
      this.modified = false
    })
  },
  methods: {
    async loadData() {
      if (this.id) {
        try {
          this.record = (await ApiService.get(this.module, this.id)).data
        } catch (error) {
          this.done(`Could not load ${this.type}`, true)
          console.error(error)
        }
      }
    },
    recordToSave() {
      return this.record
    },
    async save() {
      const record = this.recordToSave()
      this.loading = true
      try {
        if (this.id) {
          await ApiService.update(this.module, this.id, record)
        } else {
          this.createdId = (
            await ApiService.create(this.module, record)
          ).data.id
        }
        this.done(`${this.type} saved successfully`)
      } catch (error) {
        this.done(`Could not save ${this.type}`, true)
        console.error(error)
      }
    },
    async remove() {
      try {
        this.loading = true
        if ((await ApiService.delete(this.module, this.id)).data) {
          this.done(`${this.type} deleted successfully`)
        }
      } catch (error) {
        this.done(`Could not delete ${this.type}`, true)
      }
    },
    done(message, error) {
      this.loading = false
      if (!error) {
        this.modified = false
        this.back()
      }
      EventBus.$emit('feedback', {
        message,
        error,
      })
    },
    okToLeave() {
      return (
        !this.modified ||
        window.confirm(
          'Some changes have not been saved. Do you want to navigate away?',
        )
      )
    },
    listRouteParams() {
      return {}
    },
    back() {
      if (this.popup) {
        this.$emit('back', this.createdId)
      } else {
        this.$router.push({ name: this.module, params: this.listRouteParams() })
      }
    },
    beforeWindowUnload(e) {
      if (this.modified) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.okToLeave()) {
      next()
    } else {
      next(false)
    }
  },
}
