<template>
  <v-dialog persistent :value="true" width="400">
    <v-card>
      <v-card-title>
        <span class="text-h5">Login</span>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Email"
              v-model="email"
              type="email"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Password"
              type="password"
              v-model="password"
              @keyup.native.enter="login"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert text type="error" v-if="failed" class="mt-4 mb-n2">
          Could not log in, please check your credentials
        </v-alert>
      </v-card-text>
      <v-card-actions class="mr-2 pb-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="login"
          :loading="loading"
          :disabled="!email || !password || loading"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'

export default {
  name: 'LoginForm',
  data: () => ({
    email: null,
    password: null,
    loading: false,
    failed: false,
  }),
  computed: {
    ...mapState(['user']),
  },
  async beforeCreate() {
    const userData = localStorage.getItem('user')
    if (userData) {
      await this.$store.dispatch('setUser', JSON.parse(userData))
    }
  },
  methods: {
    async login() {
      this.failed = false
      this.loading = true
      try {
        const user = (
          await ApiService.post('auth/login', {
            email: this.email,
            password: this.password,
          })
        ).data
        await this.$store.dispatch('setUser', user)
      } catch (exc) {
        console.error(exc)
        this.failed = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
