<template>
  <v-navigation-drawer
    app
    clipped
    permanent
    expand-on-hover
    dark
    color="primary"
  >
    <v-list nav>
      <template v-for="(item, index) in menu">
        <router-link
          :to="{ name: item.route, params: { season, club, ageGroup } }"
          class="menu-item"
          v-if="item.route"
          :key="index"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </router-link>
        <v-divider v-else-if="!item.route" :key="index" class="divider" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Menu',
  props: {
    season: String,
    club: String,
    ageGroup: String,
  },
  computed: {
    ...mapState(['menu', 'user']),
  },
}
</script>

<style scoped>
.menu-item {
  text-decoration: none;
}
.divider {
  margin: 10px 0;
}
</style>
