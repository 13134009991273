<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="mr-4">Fixtures</span>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn v-if="false" color="primary" dark class="mb-2" @click="add">
            <v-icon left> mdi-plus </v-icon> Add Fixture
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.time`]="{ item }">
        <b>{{ timeText(item) }}</b>
      </template>
    </v-data-table>
    <ListFooter
      :module="module"
      @updated="loadData"
      :itemsCount="listData.total"
    ></ListFooter>
  </v-card>
</template>

<script>
import { format, parse } from 'date-fns'
import ListMixin from '@/mixins/ListMixin'

export default {
  name: 'FixturesList',
  mixins: [ListMixin],
  props: {
    campaign: Object,
  },
  data() {
    return {
      module: 'fixtures',
      formRoute: null,
      headers: [
        { text: 'Round', value: 'number', align: 'right' },
        { text: 'Date', value: 'date', align: 'center' },
        { text: 'Time', value: 'time', align: 'center' },
        { text: 'Venue', value: 'venue', align: 'center' },
      ],
    }
  },
  methods: {
    loadDataParams() {
      return { campaign_id: this.campaign.id }
    },
    timeText(fixture) {
      if (fixture.time) {
        return format(parse(fixture.time, 'HH:mm:ss', new Date()), 'h:mmaaa')
      }
    },
  },
}
</script>
