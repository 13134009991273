<template>
  <div>
    <Progress v-if="loading" />
    <v-row v-else-if="!admin && !availability.id && !fillingIn" class="my-8">
      <v-col cols="12" align="center">
        <v-btn color="green" dark x-large @click="fillingIn = true">
          <v-icon left> mdi-shoe-cleat </v-icon>
          Register Availability
        </v-btn>
      </v-col>
    </v-row>
    <v-form v-else v-model="valid" ref="form">
      <v-row class="my-8">
        <v-col cols="12" sm="6">
          <Player
            :player="availability"
            :color="player.color"
            @click="$emit('player-click')"
          />
          can play this weekend:
          <v-radio-group
            v-model="availability.available"
            :rules="[(v) => parseInt(v) > -1 || 'Answer is required']"
            class="pl-2"
          >
            <v-radio label="Yes" :value="1"></v-radio>
            <v-radio label="No" :value="0"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" v-if="availability.available == 1">
          One of the parents / guardians can help
          <b>with coaching and subs</b> this weekend:
          <v-radio-group v-model="availability.parent_helper" class="pl-2">
            <v-radio label="Yes" :value="1"></v-radio>
            <v-radio label="No" :value="0"></v-radio>
          </v-radio-group>
          <v-text-field
            v-if="availability.parent_helper == 1"
            label="Parent helper first name *"
            v-model="availability.parent_first_name"
            :rules="[(v) => !!v || 'Name is required']"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-if="availability.available == 1 && this.atHomeVenue"
        >
          One of the parents / guardians can help
          <b>at the venue</b> this weekend:
          <v-radio-group v-model="availability.venue_helper" class="pl-2">
            <v-radio label="Yes" :value="1"></v-radio>
            <v-radio label="No" :value="0"></v-radio>
          </v-radio-group>
          <v-text-field
            v-if="availability.venue_helper == 1"
            label="Venue helper first name *"
            v-model="availability.helper_first_name"
            :rules="[(v) => !!v || 'Name is required']"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-if="availability.venue_helper == 1"
          class="mb-4"
        >
          Roles venue helper can help with
          <v-checkbox
            v-for="role in roles"
            :key="role.id"
            v-model="availability.helper_roles"
            :value="role.id"
            hide-details
            class="role-checkbox"
            ><template v-slot:label>
              <div>
                {{ role.name }}
              </div>
              <div
                class="text-caption"
                v-if="role.description || role.time_slot"
              >
                {{ role.description }}
                <span v-if="role.time_slot">({{ role.time_slot }})</span>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Additional comments"
            v-model="availability.comment"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="text-caption text-right"
          :class="{
            'red--text': !valid,
            'white--text': valid,
          }"
        >
          * indicates required fields
        </v-col>
        <v-col cols="12" align="right">
          <v-btn v-if="admin" @click="$emit('close')" class="mr-6">
            Close
          </v-btn>
          <v-btn
            color="primary"
            :dark="valid"
            @click="save"
            :disabled="!valid"
            :loading="loading"
          >
            <v-icon left v-if="admin"> mdi-content-save </v-icon>
            {{ admin ? 'Save' : availability.id ? 'Update' : 'Submit' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import ApiService from '@/services/ApiService'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'
import U8HomeVenueMixin from '@/mixins/U8HomeVenueMixin'

export default {
  name: 'Registration',
  mixins: [U8HomeVenueMixin],
  components: {
    Player,
    Progress,
  },
  props: {
    player: Object,
    fixture: Object,
    admin: Boolean,
  },
  data: () => ({
    valid: false,
    loading: true,
    availability: {},
    roles: [],
    fillingIn: false,
  }),
  computed: {
    available() {
      return this.availability.available
    },
    parentHelper() {
      return this.availability.parent_helper
    },
    atHomeVenue() {
      return (
        this.fixture.venue_id === this.player.venue_id ||
        this.adjustedHomeVenue(
          this.player.age_group,
          this.player.club_name,
          this.fixture.number,
        )
      )
    },
    venueHelper() {
      return this.availability.venue_helper
    },
  },
  watch: {
    availability: {
      deep: true,
      handler() {},
    },
    available(newValue, oldValue) {
      if (oldValue) {
        this.availability.parent_helper = 0
        this.availability.venue_helper = 0
      }
    },
    parentHelper(newValue, oldValue) {
      if (oldValue) {
        this.availability.parent_first_name = null
      } else {
        this.$nextTick(() => {
          this.$refs.form.validate()
        })
      }
    },
    venueHelper(newValue, oldValue) {
      if (oldValue) {
        this.availability.helper_first_name = null
        this.availability.helper_roles = []
      } else {
        this.$nextTick(() => {
          this.$refs.form.validate()
        })
      }
    },
  },
  async mounted() {
    this.loading = true
    try {
      if (this.atHomeVenue) {
        this.roles = (
          await ApiService.list('helperRoles', {
            campaign_id: this.player.campaign_id,
          })
        ).data
      }
      this.availability = (
        await ApiService.find('availabilities', {
          player_id: this.player.id,
          fixture_id: this.fixture.id,
        })
      ).data
      if (!this.availability.id) {
        const player = (await ApiService.get('players', this.player.id)).data
        this.availability = {
          number: player.number,
          first_name: player.first_name,
          last_name_initial: player.last_name_initial,
          helper_roles: [],
        }
      }
    } finally {
      this.loading = false
    }
  },
  methods: {
    async save() {
      this.loading = true
      let error = false
      let message = this.admin
        ? 'Availability saved successfully'
        : 'Availability has been successfully submitted, thank you!'
      try {
        await ApiService._internalPost('availabilities', {
          player_id: this.player.id,
          fixture_id: this.fixture.id,
          ...this.availability,
        })
        this.$emit('saved')
      } catch (exc) {
        error = true
        message = this.admin
          ? 'Could not save availability'
          : 'Could not submit availability, please contact team manager directly'
        console.error(exc)
      } finally {
        EventBus.$emit('feedback', {
          message,
          error,
        })
        this.loading = false
      }
    },
  },
}
</script>

<style>
.role-checkbox .v-label {
  display: inline !important;
}
</style>
