import axios from 'axios'
import store from '@/store'
import qs from 'qs'

const apiService = axios.create({
  baseURL: `${location.protocol}//${process.env.VUE_APP_API_URL}v1/`,
})

apiService.interceptors.request.use(
  (config) => {
    const user = store.state.user
    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('setUser', null)
    }
    return Promise.reject(error)
  },
)

const ApiService = {
  list(module, options) {
    return this._get(`/${module}?${qs.stringify(options)}`)
  },
  find(module, options) {
    return this._get(`/${module}/find?${qs.stringify(options)}`)
  },
  all(module, options) {
    return this._get(`/${module}/all?${qs.stringify(options)}`)
  },
  get(module, id, options) {
    return this._get(`/${module}/${id}?${qs.stringify(options)}`)
  },
  create(module, payload) {
    return this._post(`/${module}`, payload)
  },
  update(module, id, payload) {
    return this._put(`/${module}/${id}`, payload)
  },
  delete(module, id) {
    return this._delete(`/${module}/${id}`)
  },

  post(path, payload) {
    return this._post(`/${path}`, payload).catch((error) =>
      Promise.reject(error),
    )
  },

  _get(url, options = { headers: {} }) {
    return apiService.get(url, options).catch((error) => Promise.reject(error))
  },

  _post(url, body = {}, options = { headers: {} }) {
    return this._internalPost(url, body, options).catch((error) =>
      Promise.reject(error),
    )
  },

  _internalPost(url, body = {}, options = { headers: {} }) {
    return apiService
      .post(url, body, options)
      .catch((error) => Promise.reject(error))
  },

  _put(url, body = {}, options = { headers: {} }) {
    return apiService
      .put(url, body, options)
      .catch((error) => Promise.reject(error))
  },
  _delete(url, options = { headers: {} }) {
    return apiService
      .delete(url, options)
      .catch((error) => Promise.reject(error))
  },
}

export default ApiService
