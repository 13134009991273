<template>
  <v-card flat class="helpers px-4 pb-6">
    <Progress v-if="loading" />
    <v-card-title>
      <RoundNavigator title="Volunteers Allocation" />
    </v-card-title>
    <v-alert type="error" prominent text class="mt-4" v-if="!atHomeVenue()">
      There are no volunteers required this week
    </v-alert>
    <template v-else>
      <v-row>
        <v-col cols="6" md="3" xl="2" v-for="role in roles" :key="role.id">
          <v-card height="100%">
            <v-card-text class="role">
              <div class="text-h5 mb-2">
                {{ role.name }}
                <span v-if="role.limit > 1" class="no-print"
                  >({{ role.limit }})</span
                >
              </div>
              <v-checkbox
                v-for="helper in helpersForRole(role.id)"
                :key="helper.id"
                v-model="helper.confirmed"
                :input-value="helper.confirmed"
                :color="overLimit(role) ? 'error' : 'success'"
                hide-details
                :class="{ 'no-print': !helper.confirmed }"
              >
                <template v-slot:label>
                  <div>
                    <div>{{ helper.name }}</div>
                    <div class="text-caption">({{ helper.player }})</div>
                  </div>
                </template>
              </v-checkbox>
              <v-alert
                text
                type="error"
                v-if="overLimit(role)"
                class="mt-4 mb-0"
              >
                There is a limit of {{ role.limit }} for this role
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-4 no-print">
        <v-col cols="12" class="text-right">
          <v-btn class="ma-2" @click="clear" :disabled="confirmedCount === 0">
            <v-icon left> mdi-eraser </v-icon>
            Clear
          </v-btn>
          <v-btn class="ma-2" @click="print">
            <v-icon left> mdi-printer </v-icon>
            Print
          </v-btn>
          <v-btn color="green" class="ma-2" @click="allocate" dark>
            <v-icon left> mdi-sync </v-icon>
            Randomise
          </v-btn>
          <v-btn color="primary" class="ma-2" large @click="save">
            <v-icon left> mdi-content-save </v-icon>
            Save
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus'
import { mapState } from 'vuex'
import { sampleSize } from 'lodash'
import ApiService from '@/services/ApiService'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import HelpersMixin from '@/mixins/HelpersMixin'
import PrintableMixin from '@/mixins/PrintableMixin'
import U8HomeVenueMixin from '@/mixins/U8HomeVenueMixin'
import RoundNavigator from '@/components/admin/RoundNavigator'

export default {
  name: 'TeamsAllocation',
  components: {
    RoundNavigator,
  },
  mixins: [FixtureBasedMixin, HelpersMixin, PrintableMixin, U8HomeVenueMixin],
  props: {
    campaign: Object,
  },
  computed: {
    ...mapState(['user']),
    valid() {
      return !this.roles.some(
        (role) => this.confirmationsCount(role) === 0 || this.overLimit(role),
      )
    },
  },
  methods: {
    atHomeVenue() {
      return (
        this.fixture.venue_id === this.campaign.club_venue_id ||
        this.adjustedHomeVenue(
          this.campaign.age_group,
          this.campaign.club_name,
          this.fixture.number,
        )
      )
    },
    confirmationsCount(role) {
      return this.helpersForRole(role.id).filter((helper) => helper.confirmed)
        .length
    },
    overLimit(role) {
      return this.confirmationsCount(role) > role.limit
    },
    clear() {
      this.helpers.forEach((helper) => (helper.confirmed = 0))
    },
    allocate() {
      this.clear()
      this.roles.forEach((role) => {
        sampleSize(this.helpersForRole(role.id), role.limit).forEach(
          (helper) => (helper.confirmed = 1),
        )
      })
      this.sortHelpers()
    },
    async save() {
      this.loading = true
      let error = false
      let message = 'Helpers have been successfully saved'
      try {
        const availabilities = {}
        this.helpers.forEach((helper) => {
          let availability = availabilities[helper.id] || {
            id: helper.id,
            helper_roles: {},
          }
          availability.helper_roles[helper.roleId] = {
            confirmed: helper.confirmed,
          }
          availabilities[helper.id] = availability
        })
        await ApiService.update(
          'helperRoles',
          'confirm',
          Object.values(availabilities),
        ).data
        this.sortHelpers()
      } catch (exc) {
        error = true
        message = 'Could not save helpers'
        console.error(exc)
      }
      EventBus.$emit('feedback', { message, error })
      this.loading = false
    },
  },
}
</script>

<style>
@media print {
  .helpers .v-input--selection-controls__input {
    display: none;
  }
}
</style>
