<template>
  <v-row>
    <Progress v-if="loading" />
    <FixtureDetails>
      <center>
        <Player
          :player="player"
          :color="player.color"
          class="mt-10 mb-10 block"
          @click="changePlayer"
        />
        <span class="text-caption"> (click to change player)</span>
      </center>
      <template v-if="!loading">
        <template v-if="team">
          <TeamInfo :team="team" :fixture="fixture" />
          <HelpersInfo
            v-if="fixture.venue_id === player.venue_id"
            :team="team"
            :fixture="fixture"
          />
        </template>
        <template v-else>
          <v-alert v-if="teamsSet" text type="error" class="mt-4">
            Teams have already been allocated and availability cannot be
            submitted. Please contact Team Manager directly
          </v-alert>
          <Registration
            v-else
            :player="player"
            :fixture="fixture"
            @player-click="changePlayer"
          />
        </template>
      </template>
    </FixtureDetails>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService'

import { mapState } from 'vuex'

import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import FixtureDetails from '@/components/info/FixtureDetails'
import HelpersInfo from '@/components/info/HelpersInfo'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'
import Registration from '@/components/info/Registration'
import TeamInfo from '@/components/info/TeamInfo'

export default {
  name: 'FixtureInfo',
  mixins: [FixtureBasedMixin],
  components: {
    FixtureDetails,
    HelpersInfo,
    Player,
    Progress,
    Registration,
    TeamInfo,
  },
  data: () => ({
    teams: [],
    loading: false,
  }),
  computed: {
    ...mapState(['player']),
    campaignId() {
      return this.player.campaign_id
    },
    team() {
      return this.teams.find((team) =>
        team.players.some((teamPlayer) => teamPlayer.id === this.player.id),
      )
    },
    teamsSet() {
      return this.teams.filter((team) => team.players.length > 0).length > 0
    },
  },
  watch: {
    player() {
      this.loadData()
    },
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        this.teams = (
          await ApiService.list('fixtureTeams', {
            fixture_id: this.fixture.id,
            campaign_id: this.campaignId,
          })
        ).data
      } finally {
        this.loading = false
      }
    },
    async changePlayer() {
      await this.$store.dispatch('setPlayer', null)
      await this.$store.dispatch('setFixtures', [])
      this.fillingIn = true
    },
  },
}
</script>
