<template>
  <div>
    <v-app-bar
      app
      flat
      dark
      clipped-left
      :color="campaign ? campaign.color : '#242D68'"
      class="no-print"
    >
      <v-spacer></v-spacer>
      <div class="text-h6">
        <template v-if="campaign">
          {{ campaignLabel(campaign) }}
        </template>
        <v-menu offset-y v-if="campaign && campaigns.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" light v-bind="attrs" v-on="on" class="ml-4">
              Switch
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(otherCampaign, index) in otherCampaigns"
              :key="index"
            >
              <v-list-item-icon>
                <v-icon :color="otherCampaign.color">mdi-soccer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="goTo(otherCampaign)">{{
                  campaignLabel(otherCampaign)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <UserInfo />
    </v-app-bar>
    <Menu class="no-print" :season="season" :club="club" :ageGroup="ageGroup" />
    <v-main class="main">
      <v-container fluid v-if="loggedIn() && campaign && fixtures.length">
        <router-view
          :key="`subroute-${$route.path}-${campaign.id}`"
          :campaign="campaign"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import Menu from '@/components/admin/Menu'
import UserInfo from '@/components/admin/UserInfo'

export default {
  name: 'AdminSection',
  components: {
    Menu,
    UserInfo,
  },
  props: {
    season: String,
    club: String,
    ageGroup: String,
  },
  computed: {
    ...mapState(['user', 'infoShown', 'campaigns', 'fixtures']),
    ...mapGetters(['loggedIn']),
    campaign() {
      return this.campaigns.find(
        (c) =>
          c.season === this.season &&
          c.club === this.club &&
          c.age_group === this.ageGroup,
      )
    },
    otherCampaigns() {
      return this.campaigns
        .filter((c) => c.id != this.campaign.id)
        .sort((a, b) =>
          this.campaignLabel(a).localeCompare(this.campaignLabel(b)),
        )
    },
  },
  watch: {
    user() {
      this.reload()
    },
  },
  methods: {
    async reload() {
      if (this.user === null) {
        this.$router.push({
          name: 'admin',
        })
      } else {
        let campaigns = (await ApiService.get('campaigns', 'active')).data
        await this.$store.dispatch('setCampaigns', campaigns)
        if (!this.season || !this.club || !this.ageGroup) {
          const defaultCampaign = campaigns.find(
            (campaign) => campaign.default_campaign,
          )
          await this.goTo(defaultCampaign || campaigns[0])
        } else {
          await this.updateFixtures(this.campaign)
        }
      }
    },
    async updateFixtures(campaign) {
      const fixtures = (
        await ApiService.all('fixtures', {
          campaign_id: campaign.id,
        })
      ).data.map((f) => ({ ...f, campaign_id: campaign.id }))
      await this.$store.dispatch('setFixtures', fixtures)
    },
    campaignLabel(campaign) {
      return `${campaign.age_group_name} - ${campaign.club_name}`
    },
    async goTo(campaign) {
      await this.updateFixtures(campaign)
      this.$router.push({
        name: 'availabilities',
        params: {
          season: campaign.season,
          club: campaign.club,
          ageGroup: campaign.age_group,
        },
      })
    },
  },
}
</script>

<style scoped>
.main {
  padding-left: 56px !important;
}
.menu-item {
  text-decoration: none;
}
</style>

<style>
.full-screen-container {
  max-width: 1185px;
}
.dialog-contents {
  max-height: 60vh;
}
</style>
