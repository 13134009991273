<template>
  <v-dialog persistent :value="true" width="500">
    <v-card>
      <v-card-title>
        <span class="text-h5">Find a Player</span>
      </v-card-title>
      <v-card-text class="mt-4 mb-n8">
        <v-row>
          <v-col cols="4">
            <v-text-field label="Pin" v-model="pin" outlined></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="First name"
              v-model="firstName"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert
          text
          v-model="info"
          dismissible
          close-label="Close"
          color="info"
        >
          Pin is based on 4 digits from the player's date of birth: DDMM. For
          example someone born on 5th of November has a pin of 0511.
        </v-alert>
        <v-alert text type="error" v-if="notFound">
          Could not find a player. Please double check Pin and First Name or
          contact Team Manager
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!info"
          @click="info = true"
          text
          class="text-none"
          color="info"
        >
          What is my Pin?
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="find"
          :loading="finding"
          :disabled="finding"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/services/ApiService'

export default {
  name: 'PlayerSelector',
  data: () => ({
    pin: null,
    firstName: null,
    finding: false,
    notFound: false,
    info: false,
  }),
  methods: {
    async find() {
      this.info = false
      this.finding = true
      try {
        const player = (
          await ApiService.find('players', {
            pin: this.pin,
            first_name: this.firstName,
          })
        ).data
        await this.$store.dispatch('setPlayer', player)
        const fixtures = (
          await ApiService.all('fixtures', {
            campaign_id: player.campaign_id,
          })
        ).data
        await this.$store.dispatch('setFixtures', fixtures)
      } catch (err) {
        this.notFound = true
      }
      this.finding = false
    },
  },
}
</script>
