<template>
  <div>
    <div class="full-screen"></div>
    <v-progress-linear
      height="8"
      color="primary"
      indeterminate
      class="progress"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: 'Progress',
}
</script>

<style scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10;
  opacity: 0.3;
}
.progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
</style>
