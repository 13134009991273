import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'

import ListFooter from '@/components/admin/ListFooter'

import { debounce } from 'lodash'

export default {
  components: {
    ListFooter,
  },
  data: () => ({
    listData: {},
    loading: false,
  }),
  computed: {
    ...mapState([
      'user',
      'itemsPerPage',
      'listOptions',
      'listPage',
      'listFilters',
    ]),
    listProps() {
      return {
        headers: this.headers,
        items: this.listData.data,
        itemsPerPage: this.recordsPerPage(),
        hideDefaultFooter: true,
        options: {
          ...this.listOptions[this.module],
          itemsPerPage: this.recordsPerPage(),
        },
        loading: this.loading,
        loadingText: 'Loading...',
      }
    },
    listEvents() {
      return {
        'dblclick:row': this.edit,
        'update:options': this.updateOptions,
        'update:sort-by': this.resetPage,
        'update:sort-desc': this.resetPage,
      }
    },
  },
  mounted() {
    this.loadData = debounce(this.loadData, 300)
  },
  methods: {
    loadDataParams() {
      return {}
    },
    recordsPerPage() {
      return this.itemsPerPage
    },
    async loadData() {
      this.loading = true
      try {
        const params = {
          page: this.listPage[this.module],
          perPage: this.recordsPerPage(),
          sortBy: this.listOptions[this.module].sortBy[0],
          sortDesc: this.listOptions[this.module].sortDesc[0],
          filter: JSON.stringify(this.listFilters[this.module]),
          ...this.loadDataParams(),
        }
        this.listData = (await ApiService.list(this.module, params)).data
      } finally {
        this.loading = false
      }
    },
    async updateOptions(payload) {
      await this.$store.dispatch('setListOptions', {
        list: this.module,
        options: payload,
      })
      this.loadData()
    },
    async resetPage() {
      await this.$store.dispatch('setListPage', {
        list: this.module,
        page: 1,
      })
      this.loadData()
    },
    formRouteParams() {
      return {}
    },
    add() {
      if (this.formRoute) {
        this.$router.push({
          name: this.formRoute,
          params: this.formRouteParams(),
        })
      }
    },
    edit(ignore, data) {
      if (this.formRoute) {
        this.$router.push({
          name: this.formRoute,
          params: { ...this.formRouteParams(data.item), id: data.item.id },
        })
      }
    },
  },
}
