<template>
  <v-chip
    :color="color"
    :dark="isLight(color)"
    :close="close"
    @click="$emit('click:name')"
    @click:close="$emit('click:close')"
    class="ma-2"
  >
    <b class="name text-center">{{ name }}</b>
  </v-chip>
</template>

<script>
export default {
  name: 'Team',
  props: {
    name: String,
    color: String,
    close: Boolean,
  },
  methods: {
    isLight(color) {
      if (color) {
        return color.substring(3, 5) !== 'FF'
      }
      return false
    },
  },
}
</script>

<style scoped>
.name {
  min-width: 4rem;
}
</style>
