<template>
  <div @click="$emit('click')" class="player-info">
    <v-chip
      :color="color || 'primary'"
      text-color="white"
      class="mr-2 mt-n1 font-weight-bold"
    >
      <v-icon>mdi-tshirt-crew</v-icon>
      <span v-if="player.number > 0" class="number text-center ml-2">{{
        player.number
      }}</span>
    </v-chip>
    <span v-if="!hideName" class="font-weight-bold primary--text"
      >{{ player.first_name }} {{ player.last_name_initial }}</span
    >
    <v-icon
      right
      v-if="withParent"
      :title="`Parent helper available (${player.parent_first_name})`"
      class="no-print"
      >mdi-account-plus</v-icon
    >
  </div>
</template>

<script>
export default {
  name: 'Player',
  props: {
    player: {},
    color: String,
    hideName: Boolean,
    withParent: Boolean,
  },
}
</script>

<style scoped>
.player-info {
  display: inline-block;
  white-space: nowrap;
}
.number {
  width: 1rem;
}
</style>
