import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminSection from '@/views/admin/AdminSection.vue'
import InfoSection from '@/views/info/InfoSection.vue'
import AvailabilitiesList from '@/views/admin/AvailabilitiesList.vue'
import AvailabilityForm from '@/views/admin/AvailabilityForm.vue'
import FixturesList from '@/views/admin/FixturesList.vue'
import PlayerForm from '@/views/admin/PlayerForm.vue'
import PlayersList from '@/views/admin/PlayersList.vue'
import TeamMatesList from '@/views/admin/TeamMatesList.vue'
import TeamsAllocation from '@/views/admin/TeamsAllocation.vue'
import GamesBuilder from '@/views/admin/GamesBuilder.vue'
import HelpersAllocation from '@/views/admin/HelpersAllocation.vue'
import ClusterSection from '@/views/cluster/ClusterSection.vue'
import ClusterFixtures from '@/views/cluster/ClusterFixtures.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/info' },
  {
    path: '/info/:round?',
    component: InfoSection,
  },
  {
    path: '/admin/:season?/:club?/:ageGroup?',
    name: 'admin',
    component: AdminSection,
    props: true,
    children: [
      {
        path: 'availabilities/:round?',
        name: 'availabilities',
        component: AvailabilitiesList,
      },
      {
        path: 'availability/:round/:playerId',
        name: 'availability',
        component: AvailabilityForm,
        props: true,
      },
      {
        path: 'teams/:round?',
        name: 'teams',
        component: TeamsAllocation,
        props: true,
      },
      {
        path: 'games/:round?',
        name: 'games',
        component: GamesBuilder,
        props: true,
      },
      {
        path: 'helpers/:round?',
        name: 'helpers',
        component: HelpersAllocation,
        props: true,
      },
      {
        path: 'fixtures',
        name: 'fixtures',
        component: FixturesList,
      },
      {
        path: 'players',
        name: 'players',
        component: PlayersList,
      },
      {
        path: 'player/:id?',
        name: 'player',
        component: PlayerForm,
        props: true,
      },
      {
        path: 'teammates',
        name: 'teammates',
        component: TeamMatesList,
      },
    ],
  },
  {
    path: '/cluster11',
    component: ClusterSection,
    props: true,
    children: [
      {
        path: 'fixtures/:round/:ageGroup',
        name: 'cluster-fixtures',
        component: ClusterFixtures,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

export default router
