<template>
  <v-row class="list-footer pt-10 no-print" v-if="itemsCount">
    <v-col cols="12" lg="2" md="3" class="text-subtitle-2 mt-2 pl-7 gray--text">
      {{ info }}
    </v-col>
    <v-col cols="12" lg="8" md="6" class="text-right">
      <v-pagination
        :value="listPage[module]"
        @input="updatePage"
        :length="pageCount"
        :totalVisible="7"
      ></v-pagination>
    </v-col>
    <v-col cols="12" md="1"></v-col>
    <v-col cols="6" lg="1" md="2" class="text-right pr-7">
      <v-select
        :value="itemsPerPage"
        @input="updateItemsPerPage"
        dense
        outlined
        :items="[10, 20, 30, 50]"
        label="Per page"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ListFooter',
  props: {
    module: String,
    itemsCount: Number,
  },
  computed: {
    ...mapState(['listPage', 'itemsPerPage']),
    info() {
      if (this.itemsCount === 1) {
        return `Records: 1`
      }
      const start = (this.listPage[this.module] - 1) * this.itemsPerPage
      const end = Math.min(start + this.itemsPerPage, this.itemsCount)
      return `Records: ${start + 1}-${end} of ${this.itemsCount}`
    },
    pageCount() {
      return Math.ceil(this.itemsCount / this.itemsPerPage)
    },
  },
  methods: {
    async updatePage(payload) {
      await this.$store.dispatch('setListPage', {
        list: this.module,
        page: payload,
      })
      this.$emit('updated')
    },
    async updateItemsPerPage(payload) {
      await this.$store.dispatch('setItemsPerPage', {
        list: this.module,
        itemsPerPage: payload,
      })
      this.$emit('updated')
    },
  },
}
</script>

<style>
.list-footer .v-pagination__item {
  font-size: 0.8rem;
}
.list-footer .v-select {
  font-size: 0.8rem;
}
</style>
