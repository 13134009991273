<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="mr-4">Team Mates</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.number`]="{ item }">
        <Player :player="item" :color="campaign.color" />
      </template>
      <template v-slot:[`item.previous`]="{ item }">
        <span
          v-for="mate in previousMates(item)"
          :key="`${item.id}-${mate.id}`"
          class="mr-4"
        >
          <v-badge inline :color="colour(mate.count)" :content="mate.count"
            >{{ playersById[mate.id].first_name }}
            {{ playersById[mate.id].last_name_initial }}</v-badge
          >
        </span>
      </template>
      <template v-slot:[`item.never`]="{ item }">
        <span
          v-for="mate in neverMates(item)"
          :key="`${item.id}-${mate.id}`"
          class="mr-4"
        >
          <v-badge inline color="grey"
            >{{ playersById[mate.id].first_name }}
            {{ playersById[mate.id].last_name_initial }}</v-badge
          >
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ApiService from '@/services/ApiService'
import ListMixin from '@/mixins/ListMixin'
import Player from '@/components/chips/Player'

export default {
  name: 'TeamMatesList',
  components: {
    Player,
  },
  props: {
    campaign: Object,
  },
  mixins: [ListMixin],
  data() {
    return {
      module: 'players',
      formRoute: null,
      headers: [
        { text: 'Player', value: 'number', width: '200' },
        { text: 'Previous Teammates', value: 'previous' },
        { text: 'Never Teammates', value: 'never' },
      ],
      playersById: null,
    }
  },
  methods: {
    async loadData() {
      await ListMixin.methods.loadData.call(this)
      this.loading = true
      try {
        const players = this.listProps.items
        this.playersById = players.reduce((byId, player) => {
          player.mates = players.map((player) => ({
            id: player.id,
            count: 0,
          }))
          return { ...byId, [player.id]: player }
        }, {})
        const allTeams = (
          await ApiService.all('fixtureTeams', {
            campaign_id: this.campaign.id,
          })
        ).data.map((team) => team.players.map((player) => player.id))
        allTeams.forEach((team) => {
          team.forEach((playerId) => {
            team.forEach((teammateId) => {
              if (playerId != teammateId) {
                const teammateCount = this.playersById[playerId]?.mates.find(
                  (mate) => mate.id == teammateId,
                )
                if (teammateCount) {
                  teammateCount.count++
                }
              }
            })
          })
        })
      } finally {
        this.loading = false
      }
    },
    colour(count) {
      switch (count) {
        case 1:
          return 'green'
        case 2:
          return 'amber'
        case 3:
          return 'orange'
        default:
          return 'red'
      }
    },
    previousMates(player) {
      if (this.playersById) {
        const mates = this.playersById[player.id]?.mates.slice() || []
        return mates
          .filter((mate) => mate.count > 0)
          .sort((mate1, mate2) => {
            return mate2.count - mate1.count
          })
      }
    },
    neverMates(player) {
      if (this.playersById) {
        const mates = this.playersById[player.id]?.mates.slice() || []
        return mates
          .filter((mate) => mate.id !== player.id)
          .filter((mate) => mate.count == 0)
          .sort((mate1, mate2) => {
            return this.playersById[mate1.id]?.first_name.localeCompare(
              this.playersById[mate2.id]?.first_name,
            )
          })
      }
    },
    loadDataParams() {
      return { campaign_id: this.campaign.id }
    },
    recordsPerPage() {
      return 1000
    },
  },
}
</script>
