import { mapGetters, mapState } from 'vuex'
import { format, parse, parseISO } from 'date-fns'

export default {
  data: () => ({
    fixture: {},
  }),
  computed: {
    ...mapState(['player', 'fixtures']),
    ...mapGetters(['currentFixture', 'lastFixture']),
    dateText() {
      return this.date ? format(this.date, 'eeee, MMMM do') : ''
    },
    dateTime() {
      if (this.fixture.time) {
        let timeValue = parse(this.fixture.time, 'HH:mm:ss', new Date())
        return format(timeValue, 'h:mmaaa')
      }
    },
    date() {
      return this.fixture.date ? parseISO(this.fixture.date) : null
    },
  },
  async created() {
    if (this.$route.params.round) {
      this.fixture = this.fixtures[this.$route.params.round - 1]
    } else {
      this.fixture = this.currentFixture()
    }
  },
}
