<template>
  <v-row>
    <Progress v-if="loading" />
    <v-col cols="12" v-if="confirmedCount > 0">
      <v-card elevation="0">
        <v-card-text>
          <p class="text-h5">Home Venue Volunteers</p>
          <div v-for="role in roles" :key="role.id">
            <template v-if="confirmedHelpers(role).length > 0">
              <div class="mt-6 mb-6">
                {{ role.name }}
                <div
                  class="text-caption"
                  v-if="role.description || role.time_slot"
                >
                  {{ role.description }}
                  <span v-if="role.time_slot">({{ role.time_slot }})</span>
                </div>
                <ul class="font-weight-bold">
                  <li v-for="helper in confirmedHelpers(role)" :key="helper">
                    {{ helper }}
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HelpersMixin from '@/mixins/HelpersMixin'

export default {
  name: 'HelpersInfo',
  mixins: [HelpersMixin],
  props: {
    fixture: Object,
  },
  methods: {
    confirmedHelpers(role) {
      return this.helpersForRole(role.id)
        .filter((helper) => helper.confirmed)
        .map((helper) => `${helper.name} (${helper.player})`)
    },
  },
}
</script>
