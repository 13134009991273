<template>
  <div>
    <v-card>
      <v-responsive :aspect-ratio="16 / 9">
        <div ref="map" style="width: 100%; height: 100%" />
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

const mapLoader = new Loader({
  apiKey: 'AIzaSyBqmQ8EkHerA6laCoVbpmxOJLaY91tZHx8',
  version: 'weekly',
  libraries: ['places'],
})

export default {
  name: 'Map',
  props: {
    latitude: Number,
    longitude: Number,
  },
  data: () => ({
    google: null,
    map: null,
    geocoder: null,
  }),
  mounted() {
    mapLoader
      .load()
      .then((google) => {
        this.google = google
        this.map = new this.google.maps.Map(this.$refs.map, {
          zoom: 16,
          center: {
            lat: this.latitude,
            lng: this.longitude,
          },
        })
        const marker = new google.maps.Marker({
          position: { lat: this.latitude, lng: this.longitude },
        })
        marker.setMap(this.map)
      })
      .catch((e) => {
        console.error(e)
      })
  },
}
</script>
