<template>
  <v-row class="mt-1">
    <Progress v-if="loading" />
    <v-col class="d-flex justify-center">
      <v-sheet width="1024" rounded="lg" class="pa-6">
        <v-row>
          <v-col class="text-h6"> Availability </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              filled
              label="Date"
              :value="dateText"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              filled
              label="Venue"
              :value="fixture.venue"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <Registration
          v-if="playerData"
          :admin="true"
          :player="playerData"
          :fixture="fixture"
          @saved="back"
          @close="back"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService'

import Progress from '@/components/utils/Progress'
import Registration from '@/components/info/Registration'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'

export default {
  name: 'AvailabilityForm',
  mixins: [FixtureBasedMixin],
  components: {
    Progress,
    Registration,
  },
  props: {
    playerId: [String, Number],
    campaign: Object,
  },
  data: () => ({
    loading: true,
    playerData: null,
  }),
  async created() {
    this.playerData = (
      await ApiService.find('players', {
        id: this.playerId,
      })
    ).data
    this.loading = false
  },
  methods: {
    back() {
      this.$router.push({
        name: 'availabilities',
        params: {
          round: this.fixture.number,
          season: this.campaign.season,
          club: this.campaign.club,
          ageGroup: this.campaign.age_group,
        },
      })
    },
  },
}
</script>
