<template>
  <v-footer app>
    <div class="caption">#{{ version }}</div>
    <v-spacer></v-spacer>
    <div class="caption">
      by
      <a
        href="http://keepapp.com.au"
        target="_blank"
        class="keepapp font-weight-bold"
        >KeepApp</a
      >
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    version: String,
  },
}
</script>

<style scoped>
.keepapp {
  color: #0ba4b6;
}
</style>
