<template>
  <v-row align="center" dense>
    <v-col cols="3" align="center" class="text-caption">
      {{ adjustedPitchName(game.pitch, game.order) }}
    </v-col>
    <v-col cols="4" align="center">
      <Team :name="game.home_team" :color="game.home_team_color" />
    </v-col>
    <v-col cols="4" align="center">
      <Team :name="game.away_team" :color="game.away_team_color" />
    </v-col>
  </v-row>
</template>

<script>
import Team from '@/components/chips/Team'

export default {
  name: 'GameInfo',
  components: {
    Team,
  },
  props: {
    game: {},
  },
  methods: {
    adjustedPitchName(pitchName, roundIndex) {
      if (pitchName.indexOf('am') && roundIndex > 1) {
        return pitchName.replace('am', ':30')
      }
      return pitchName
    },
  },
}
</script>
