<template>
  <span class="pa-2">
    <v-chip v-if="value === 1" color="green" text-color="white">
      <v-avatar left>
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-avatar>
      {{ label || 'Yes' }}
    </v-chip>
    <v-chip v-else-if="value === 0" color="red" text-color="white">
      <v-avatar left>
        <v-icon>mdi-cancel</v-icon>
      </v-avatar>
      {{ label || 'No' }}
    </v-chip>
    <v-chip v-else color="grey" text-color="white">
      <v-avatar left>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-avatar>
      {{ label || 'Unknown' }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'YesNo',
  props: {
    value: Number,
    label: String,
  },
}
</script>
