<template>
  <v-row>
    <v-col
      cols="12"
      class="text-caption text-right"
      :class="{
        'pr-4': !text,
        'pr-6': text,
        'primary--text': !valid,
        'white--text': valid,
      }"
    >
      * indicates required fields
    </v-col>
    <v-col cols="12" sm="6">
      <ConfirmDeleteDialog
        v-if="showDelete"
        :recordType="recordType"
        @confirmed="$emit('delete')"
      />
    </v-col>
    <v-col cols="12" sm="6" class="text-right">
      <v-btn @click="$emit('cancel')" :text="text" :class="{ 'mr-6': !text }">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        @click="$emit('save')"
        :disabled="!valid"
        :text="text"
      >
        <v-icon left v-if="!text"> mdi-content-save </v-icon>
        Save
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ConfirmDeleteDialog from './ConfirmDeleteDialog'

export default {
  name: 'FormButtons',
  components: {
    ConfirmDeleteDialog,
  },
  props: {
    valid: Boolean,
    showDelete: Boolean,
    recordType: String,
    text: Boolean,
  },
}
</script>
